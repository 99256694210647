import React, { useEffect } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../../redux/actions'
import { PATH_NAME } from '../../../utils';
import "./ProductKeConLanTrungTai.scss"
import PageTitle from '../../Common/PageTitle/PageTitle';
import { setMenuActive } from '../../../redux/actions'
import SilderImageThumbnail from '../../Common/SilderImageThumbnail/SilderImageThumbnail';
import ProductDesc from '../../Common/ProductDesc/ProductDesc';
import ListProductSidebar from '../../Common/ListProductSidebar/ListProductSidebar';
import ProductShortDesc from '../../Common/ProductShortDesc/ProductShortDesc';
import ProductDetailDesc from '../../Common/ProductDetailDesc/ProductDetailDesc';

import img1 from "../../../assets/images/product_collections/product_ke_con_lan_trung_tai/img1.jpg"
import img2 from "../../../assets/images/product_collections/product_ke_con_lan_trung_tai/img2.jpg"


const ProductKeConLanTrungTai = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const images = [
        {
            original: img1,
            thumbnail: img1,
        },
        {
            original: img2,
            thumbnail: img2,
        },
    ];

    return (
        <div className='product-ke-driver-in'>
            <PageTitle
                listPageTitle={[
                    {
                        path: PATH_NAME.COLLECTIONS,
                        title: "Giá kệ"
                    },
                    {
                        path: PATH_NAME.KE_DRIVER_IN,
                        title: "Kệ con lăn trung tải"
                    }
                ]}
            />
            <section className="content">
                <div className="content-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-9">
                                <div className="postcontent">
                                    <div className="product-page">
                                        <div className="single-product">
                                            <div className="row">
                                                <div className="col-12 col-md-7">
                                                    <SilderImageThumbnail
                                                        images={images}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-5">
                                                    <ProductDesc
                                                        productDesc={{
                                                            title: "Kệ con lăn trung tải"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <ProductShortDesc
                                            listDesc={[
                                                "Tải trọng 50-80kg / 3 làn roller nhựa",
                                                "Không cần sử dụng xe nâng, công nhân xuất nhập hàng hóa trực tiếp trên kệ",
                                                "Tăng khả năng lưu trữ thêm 50%",
                                            ]}

                                        />
                                        <ProductDetailDesc>
                                            <div class="tab-content clearfix ui-tabs-panel ui-widget-content ui-corner-bottom" id="tabs-1" aria-labelledby="ui-id-1" role="tabpanel" aria-expanded="true" aria-hidden="false">
                                                <p>
                                                </p><p><span><span >Kệ con lăn được thiết kế phục vụ cho mục đích nhập trước - xuất trước.&nbsp;<br />- Hệ thống bao gồm 2 phần chính: kết cấu khung kệ và ray trượt
                                                </span>
                                                </span>
                                                </p><p><span><span  >- Ray dẫn động có gắn con lăn và được đặt dốc theo chiều sâu của kệ cho phép khi có một kiện hàng được lấy ra thì kệ sau nó tự động trượt vào chỗ trống
                                                </span>
                                                </span>
                                                </p><hr /><p><span style={{ fontSize: "15px" }}><strong>Nhà sản xuất giá kệ công nghiệp hàng đầu Việt Nam
                                                </strong>
                                                </span>
                                                </p><p><span style={{ fontSize: "15px" }}>Kệ công nghiệp Cơ Khí TH Việt Nam được sản xuất từ sắt thép tiêu chuẩn JIS G3101 SS400 với khả năng chịu lực và độ bền cực cao. Dây chuyển sản xuất khép kín, hiện đại với tiêu chuẩn quốc tế, công nghệ phun sơn tĩnh điện&nbsp;giúp sản phẩm&nbsp;có tuổi thọ&nbsp;dài lâu, chống cháy nổ, rỉ sét.&nbsp;
                                                </span>
                                                </p><p><span style={{ fontSize: "15px" }}>Đến với Cơ Khí TH Việt Nam, khách hàng&nbsp;sẽ nhận được tư vấn miễn phí về các vấn đề setup, lựa chọn kệ cho kho hàng.&nbsp;Cơ Khí TH Việt Nam hỗ trợ khách hàng lắp đặt và vận chuyển trên toàn quốc. Với chế độ bảo hành lên tới&nbsp;3&nbsp;năm, quý khách hàng hoàn toàn yên tâm&nbsp;về quá trình sử dụng. Đội ngũ kỹ thuật của Cơ Khí TH Việt Nam luôn sẵn sàng hỗ trợ khách hàng trong trường hợp cần thiết.&nbsp;
                                                </span>
                                                </p><p><span style={{ fontSize: "15px" }}>Khách hàng có nhu cầu lắp đặt giá kệ công nghiệp vui lòng liên hệ với chúng tôi để được tư vấn và báo giá chính xác nhất:
                                                </span>
                                                </p>
                                            </div>
                                        </ProductDetailDesc>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <ListProductSidebar />
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >
    )
}

export default ProductKeConLanTrungTai